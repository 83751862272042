import { useState } from "react"
import CakeCard from "./CakeCard"
import cakes from "../../assets/data/cakes"

import styles from "./Cakes.module.scss"

const Cakes = () => {
	const [hoveredIndex, setHoveredIndex] = useState(null)

	const cakeCards = cakes.map((cake, index) => (
		<CakeCard
			key={index}
			isHovered={hoveredIndex === index}
			isAnyCardHovered={hoveredIndex !== null}
			onHover={() => setHoveredIndex(index)}
			onLeave={() => setHoveredIndex(null)}
			flavor={cake.flavor}
			sponge={cake.sponge}
			filling={cake.filling}
			icing={cake.icing}
		/>
	))
	return (
		<div className={styles.page}>
			<h1 className={styles.h1}>Cakes</h1>
			<div className={styles.container}>{cakeCards}</div>
		</div>
	)
}

export default Cakes
