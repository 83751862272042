import React from "react"
import { Link } from "react-router-dom"
import PhotoCard from "../PhotoCard"

import styles from "./index.module.scss"
const PhotoInfoCard = ({
	type,
	photoObj,
	title,
	subtitle,
	content,
	photoRotation,
	ctaText,
	ctaLink,
	useATag,
}) => {
	return (
		<div
			className={`${styles.section} ${type === "right" ? styles.right : styles.left}`}
		>
			<div className={styles.photos}>
				<PhotoCard
					inStyles={{ transform: `rotate(${photoRotation})` }}
					photoSet={photoObj}
				/>
			</div>
			<div className={styles.content}>
				<h2 className={styles.subtitle}>{subtitle}</h2>
				<h1 className={styles.title}>{title}</h1>
				<p className={styles.text}>{content}</p>
				{useATag && (
					<a
						className={styles.link}
						href={ctaLink}
						target="_blank"
						rel="noopener noreferrer"
					>
						<button className={styles.button}>{ctaText}</button>
					</a>
				)}
				{!useATag && (
					<Link className={styles.link} to={ctaLink}>
						<button className={styles.button}>{ctaText}</button>
					</Link>
				)}
			</div>
		</div>
	)
}

export default PhotoInfoCard
