// Form data to populate selection/options on the Order Form

export const cakeTypes = [
  "Birthday Cake (adult)",
  "Birthday Cake (child)",
  "Smash Cake (1st Birthday)",
  "Number Cake",
  "Baby Shower",
  "Retirement",
  "Anniversary",
  // "Wedding Cake",
  "Other (specify in message)",
];

export const cakeTiers = ["1", "2", "3"];

export const cakeFlavors = [
  {
    value: "Black Forest",
    description: "Chocolate cake + cherry filling + chocolate mascarpone cream",
  },
  {
    value: "Strawberry Delight",
    description:
      "Strawberry cake + cream and strawberries + strawberry buttercream",
  },
  {
    value: "Raspberry Truffle",
    description:
      "Chocolate cake + raspberry cream filling + chocolate mascarpone cream",
  },
  {
    value: "Bueno Mascarpone",
    description:
      "Marbled cake + Kinder-bueno mascarpone cream + chocolate mascarpone cream",
  },
  {
    value: "Peanut Butter & Jelly",
    description: "Chocolate cake + strawberry jam + peanut butter buttercream",
  },
  {
    value: "Cookies & Cream",
    description:
      "Chocolate cake + cookies and cream filling + oreo buttercream",
  },
  {
    value: "Gluten-Free",
    description:
      "Almond cake + fresh raspberries + lemon raspberry buttercream",
  },
  {
    value: "Pumpkin Spice",
    description:
      "Pumpkin spice cake + salted caramel filling + cream cheese buttercream",
  },
  {
    value: "Other (specify in message)",
    description:
      "Please outline your cake flavors in the 'Message' box at the end of this form",
  },
];

export const deliveryOptions = {
  "option-a": "Pick Up - Free",
  "option-b": "Delivery - $15",
};

export const cityOptions = [
  "Alpine",
  "American Fork",
  "Benjamin",
  "Cedar Fort",
  "Cedar Hills",
  "Cedar Valley",
  "Eagle Mountain",
  "Elberta",
  "Elk Ridge",
  "Fairfield",
  "Genola",
  "Goshen",
  "Highland",
  "Lehi",
  "Lindon",
  "Mammoth",
  "Mapleton",
  "Orem",
  "Payson",
  "Pleasant Grove",
  "Provo",
  "Salem",
  "Santaquin",
  "Saratoga Springs",
  "Spanish Fork",
  "Springville",
  "Vineyard",
  "Woodland Hills",
]
