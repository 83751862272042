import React, { useState, useRef, useEffect } from "react"
import debounce from "lodash/debounce"
import ArrowButton from "../../general/ArrowButton"

import styles from "./Carousel.module.scss"

const Carousel = ({ photos }) => {
	const [photoIndex, setPhotoIndex] = useState(1)
	const photoContainer = useRef()

	useEffect(() => {
		photoContainer.current.children[1].scrollIntoView({
			behavior: "instant",
			inline: "center",
		})
		const current = photoContainer.current
		const onScroll = debounce(calculateIndex, 100)
		current.addEventListener("scroll", onScroll)
		return () => current.removeEventListener("scroll", onScroll)
	}, [photos.length])

	const calculateIndex = () => {
		// Get element variables for calculation
		const cardOneLeft =
			photoContainer.current.children[0].getBoundingClientRect().left
		const cardTwoLeft =
			photoContainer.current.children[1].getBoundingClientRect().left
		const scrollPosition = photoContainer.current.scrollLeft

		// Calculate index
		const cardWidth = cardTwoLeft - cardOneLeft
		const currentIndex = Math.floor(scrollPosition / cardWidth)
		setPhotoIndex(currentIndex)
	}

	const handlePrev = () => {
		const newIndex = photoIndex - 1
		setPhotoIndex(newIndex)
		photoContainer.current.children[newIndex].scrollIntoView({
			behavior: "smooth",
			inline: "center",
		})
	}

	const handleNext = () => {
		const newIndex = photoIndex + 1
		setPhotoIndex(newIndex)
		photoContainer.current.children[newIndex].scrollIntoView({
			behavior: "smooth",
			inline: "center",
		})
	}

	const goToIndex = (index) => {
		setPhotoIndex(index)
		photoContainer.current.children[index].scrollIntoView({
			behavior: "smooth",
			inline: "center",
		})
	}

	const indicators = photos.map((_, index) => {
		return (
			<button
				key={index}
				className={`${styles.indicator} ${index === photoIndex ? styles.active : styles.inactive}`}
				onClick={() => goToIndex(index)}
			></button>
		)
	})

	return (
		<div className={styles.container}>
			{photoIndex > 0 && (
				<ArrowButton
					arrowLocation="left"
					buttonSize="30px"
					arrowSize="1em"
					theme={"dark"}
					inStyles={{
						position: "absolute",
						top: "50%",
						left: "10%",
						zIndex: 5,
					}}
					handleOnClick={() => handlePrev()}
				/>
			)}
			<div ref={photoContainer} className={styles.photosContainer}>
				{photos}
			</div>
			{photoIndex < photos.length - 1 && (
				<ArrowButton
					arrowLocation="right"
					buttonSize="30px"
					arrowSize="1em"
					theme={"dark"}
					inStyles={{
						position: "absolute",
						top: "50%",
						right: "10%",
						zIndex: 5,
					}}
					handleOnClick={() => handleNext()}
				/>
			)}
			<span className={styles.indicators}>{indicators}</span>
		</div>
	)
}

export default Carousel
