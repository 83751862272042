import photo_liliana_1 from "./pc_liliana_1.jpeg"
import photo_liliana_2 from "./pc_liliana_2.jpeg"
import photo_liliana_3 from "./pc_liliana_3.jpeg"
import photo_zoe_1 from "./pc_zoe_1.jpeg"
import photo_zoe_2 from "./pc_zoe_2.jpeg"
import photo_zoe_3 from "./pc_zoe_3.jpeg"
import photo_zoe_4 from "./pc_zoe_4.jpeg"
import photo_michelle_1 from "./pc_michelle_1.jpeg"
import photo_luke_1 from "./pc_luke_1.jpeg"
import photo_amelia_1 from "./pc_amelia_1.jpeg"
import photo_amelia_2 from "./pc_amelia_2.jpeg"
import photo_amelia_3 from "./pc_amelia_3.jpeg"
import photo_uru_1 from "./pc_uru_1.jpeg"

// About Page specific photos
import photo_milena_1 from "./milena_with_cake.jpg"
import photo_milena_2 from "./family_in_poland.jpg"
import photo_milena_3 from "./family_birthday_party.jpg"
import photo_milena_4 from "./milena_in_gdansk.jpg"

export const photo_milena_cake = {
	title: "Milena w/ Chocolate Cake",
	images: [photo_milena_1],
}

export const photo_family_poland = {
	title: "Family in Poland",
	images: [photo_milena_2],
}

export const photo_family_birthday = {
	title: "Lilly's Birthday Party",
	images: [photo_milena_3],
}

export const photo_milena_gdansk = {
	title: "Milena in Gdansk",
	images: [photo_milena_4],
}

export const photos_liliana = {
	title: "Liliana's 1st Birthday",
	images: [photo_liliana_1, photo_liliana_2, photo_liliana_3],
}

export const photos_zoe = {
	title: "Zoe's 1st Birthday",
	images: [photo_zoe_1, photo_zoe_2, photo_zoe_3, photo_zoe_4],
}

export const photos_michelle = {
	title: "Lexi's Baby Shower",
	images: [photo_michelle_1],
}

export const photos_luke = {
	title: "Luke's 30th Birthday",
	images: [photo_luke_1],
}

export const photos_amelia = {
	title: "Amelia's 3rd Birthday",
	images: [photo_amelia_1, photo_amelia_2, photo_amelia_3],
}

export const photos_uru = {
	title: "Uru's 34th Birthday",
	images: [photo_uru_1],
}

const imagesObjects = [
	photos_liliana,
	photos_zoe,
	photos_michelle,
	photos_luke,
	photos_amelia,
	photos_uru,
]

export default imagesObjects
