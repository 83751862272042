import React from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faClock } from "@fortawesome/free-solid-svg-icons"
import styles from "./Input.module.scss"

const TimeDisplay = ({
	value,
	error,
	autoFocus,
	closeDial,
	saveBtnIsVisible,
}) => {
	const handleChange = (e) => {
		e.preventDefault()
		e.stopPropagation()
	}

	// format time to remove leading zero (if any)
	const formatTime = (value) => {
		let [hour, minuteAndMeridian] = value.split(":")
		let [minute, meridian] = minuteAndMeridian.split(" ")
		return `${parseInt(hour, 10)}:${minute} ${meridian}`
	}

	const handleInputSave = (e) => {
		e.stopPropagation()
		e.preventDefault()
		e.target.focus()
		closeDial()
	}

	return (
		<div className={styles.container}>
			<input
				className={`${styles.input} ${value ? styles.active : ""} ${error ? styles.invalid : ""}`}
				id="time-input"
				style={{ width: "100%" }}
				type="text"
				value={value ? formatTime(value) : ""}
				readOnly="readonly"
				onChange={handleChange}
				autoFocus={autoFocus}
				tabIndex={0}
			/>
			{/* FIXME: fix the requirement to 'double-tap' the 'save' button on mobile (iPhone so far) */}
			<button
				tabIndex={-1}
				className={`${styles.save} ${saveBtnIsVisible ? styles.show : ""}`}
				onClick={handleInputSave}
			>
				Save
			</button>
			<FontAwesomeIcon icon={faClock} className={styles.icon} />
		</div>
	)
}

export default TimeDisplay

TimeDisplay.propTypes = {
	value: PropTypes.string,
	autoFocus: PropTypes.bool,
}
