import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
	faChevronLeft,
	faChevronRight,
} from "@fortawesome/free-solid-svg-icons"

import styles from "./ArrowButton.module.scss"

const ArrowButton = ({
	disabled,
	arrowLocation,
	buttonSize,
	arrowSize,
	theme,
	inStyles,
	handleOnClick,
}) => {
	// create style for user customization if buttonSize is set.
	let propStyles = {}
	if (buttonSize) {
		propStyles = { position: "absolute", width: buttonSize, height: buttonSize }
	}

	// set the arrow size based on the button size
	const setArrowSize = (buttonSize) => {
		// if buttonSize is empty or null than set it to 'inherit'
		if (!buttonSize) return 'inherit'

		// capture the number from the passed in string
		const numberMatch = buttonSize.match(/[\d.]+/)

		// create an object to store the values
		const sizeObject = { num: '', str: ''}

		// check if there is a number match
		if (numberMatch) {
			sizeObject.num = numberMatch[0]; // there should only be one number.

			// Remove the number from the string using replace
			sizeObject.str = buttonSize.replace(numberMatch[0], "");
		} else {
			return 'inherit' // if not number, than the value is bad and pass the default
		}

		// set variables for easy calculations
		let arrowSize = ''
		const size = sizeObject.num
		const unit = sizeObject.str
		// perform calculation
		if (unit === 'px') {
			arrowSize = size - 12
			return `${arrowSize}px`
		}
		if (unit === 'em') {
			arrowSize = size - 0.8
			return `${arrowSize}em`
		}
		if (unit === 'rem') {
			arrowSize = size - 0.8
			return `${arrowSize}em`
		}
	}

	return (
		<button
			disabled={disabled}
			style={{ ...propStyles, ...inStyles }}
			className={`${styles.button} ${theme === "light" ? styles.light : styles.dark}`}
			onClick={handleOnClick}
		>
			<FontAwesomeIcon
				style={{ height: setArrowSize(buttonSize) , width: "auto" }}
				className={`${styles.arrow} ${arrowLocation === "left" ? styles.left : styles.right}`}
				icon={arrowLocation === "left" ? faChevronLeft : faChevronRight}
			/>
		</button>
	)
}

export default ArrowButton
