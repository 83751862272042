import React from "react"

import styles from "./developer.module.scss"

const Developer = () => {
	return (
		<div style={{ alignItems: "center", width: "80vw" }}>
			<h1 className={styles.title}>Developer</h1>
			<p>Sshhh!!! You found the secret developer page!</p>
		</div>
	)
}

export default Developer
