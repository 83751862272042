import React, { useState, useRef, useCallback, useEffect } from "react"
import { createPortal } from "react-dom"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons"
import Option from "./Option"

import styles from "./Select.module.scss"

const SelectInfo = ({
	id,
	label,
	value,
	error,
	options,
	autoFocus,
	handleError,
	handleChange,
	handleFocus,
}) => {
	const [isOpen, setIsOpen] = useState(false)
	const [selectError, setSelectError] = useState(error)
	const [optionsPosition, setOptionsPosition] = useState({})
	const [selectedValue, setSelectedValue] = useState(value)
	const selectRef = useRef()
	const optionsRef = useRef()

	useEffect(() => {
		setSelectError(error)
	}, [error])

	const checkValue = useCallback(
		(newValue) => {
			if (!newValue) {
				setSelectError("Required")
				handleError("Required")
			} else {
				setSelectError("")
				handleError("")
			}
		},
		[handleError]
	)

	const onOptionClick = useCallback(
		(newValue) => {
			checkValue(newValue)
			setSelectedValue(newValue)
			handleChange(newValue)
			closeOptions()
		},
		[handleChange, checkValue]
	)

	const handleKeyDown = useCallback(
		(event) => {
			const { key } = event

			// Open the options if they are not open
			if (!isOpen) {
				openOptions()
			}

			if (key === "ArrowUp") {
				setSelectedValue((prevSelectedValue) => {
					const index = options.findIndex(
						(option) => option.value === prevSelectedValue
					)
					if (index > 0) {
						return options[index - 1].value
					} else {
						return options[options.length - 1].value
					}
				})
			} else if (key === "ArrowDown") {
				setSelectedValue((prevSelectedValue) => {
					const index = options.findIndex(
						(option) => option.value === prevSelectedValue
					)
					if (index < options.length - 1) {
						return options[index + 1].value
					} else {
						return options[0].value
					}
				})
			} else if (key === " " || key === "Enter") {
				if (isOpen) {
					onOptionClick(selectedValue)
				} else {
					openOptions()
				}
			}
		},
		[options, isOpen, onOptionClick, selectedValue]
	)

	// Scroll to ensure the highlighted item is in view
	useEffect(() => {
		// Skip if the options are not open
		if (!isOpen) {
			return
		}

		// Get the options ref and the highlighted item index
		const list = optionsRef.current
		const index = options.findIndex((option) => option.value === selectedValue)
		const item = list.children[index]

		// Scroll to the highlighted item
		if (item) {
			const itemOffsetTop = item.offsetTop
			const itemHeight = item.offsetHeight
			const listHeight = list.clientHeight
			const scrollTop = list.scrollTop

			if (itemOffsetTop < scrollTop) {
				// Scroll up
				list.scrollTop = itemOffsetTop
			} else if (itemOffsetTop + itemHeight > scrollTop + listHeight) {
				// Scroll down
				list.scrollTop = itemOffsetTop + itemHeight - listHeight
			}
		}
	}, [selectedValue, isOpen, options])

	const openOptions = () => {
		requestAnimationFrame(() => {
			const rect = selectRef.current.getBoundingClientRect()
			const scrollOffset = window.scrollY || document.documentElement.scrollTop // required to get the correct position accounting for scroll
			setOptionsPosition({
				top: `calc(${rect.bottom + scrollOffset}px + .25em)`,
				left: rect.left,
				width: rect.width,
			})
		})
		setIsOpen(true)
	}

	const closeOptions = () => {
		setIsOpen(false)
	}

	const isOptionSelected = (option) => {
		return option === selectedValue
	}

	const selectOptions = options.map((option, i) => (
		<Option
			key={i}
			option={option}
			handleChange={onOptionClick}
			handleBlur={closeOptions}
			tabIndex={-1}
			isOptionSelected={isOptionSelected(option.value)}
		/>
	))

	const selectDiv = (
		<div
			className={`${styles.input} ${selectedValue ? styles.active : ""}`}
			autoFocus={autoFocus}
			tabIndex={0}
		>
			<span className={styles.value}>{selectedValue}</span>
			<FontAwesomeIcon className={styles.selector} icon={faChevronRight} />
			{isOpen &&
				createPortal(
					<ul
						ref={optionsRef}
						style={optionsPosition}
						className={styles.options}
					>
						{selectOptions}
					</ul>,
					document.body
				)}
		</div>
	)

	return (
		<div
			id={id}
			ref={selectRef}
			className={`${styles.inputBox} ${selectError ? styles.invalid : ""}`}
			onClick={(e) => {
				e.preventDefault()
				e.stopPropagation()
				openOptions()
			}}
			onBlur={() => {
				checkValue(value)
				closeOptions()
			}}
			onFocus={() => {
				handleFocus()
			}}
			onKeyDown={(e) => {
				e.stopPropagation()
				handleKeyDown(e)
			}}
		>
			{selectDiv}
			<label
				id={`${id}-label`}
				className={styles.inputLabel}
				htmlFor={`${id}-input`}
			>
				{label}
			</label>
			{selectError && (
				<span id={`${id}-error`} className={styles.inputError}>
					{selectError}
				</span>
			)}
		</div>
	)
}

export default SelectInfo
