// TODO: Add images to each cake object
const cakesData = [
	{
		flavor: "Black Forest",
		sponge: "Chocolate",
		filling: "Cherry",
		icing: "Chocolate mascarpone cream",
	},
	{
		flavor: "Strawberry Delight",
		sponge: "Strawberry",
		filling: "Raspberry cream",
		icing: "Strawberry buttercream",
	},
	{
		flavor: "Raspberry Truffle",
		sponge: "Chocolate",
		filling: "Raspberry cream",
		icing: "Chocolate mascarpone cream",
	},
	{
		flavor: "Bueno Mascarpone",
		sponge: "Marbled",
		filling: "Kinder-bueno mascarpone cream",
		icing: "Chocolate mascarpone cream",
	},
	{
		flavor: "Peanut Butter & Jelly",
		sponge: "Chocolate",
		filling: "Strawberry jam",
		icing: "Peanut butter buttercream",
	},
	{
		flavor: "Cookies & Cream",
		sponge: "Chocolate",
		filling: "Cookies and cream",
		icing: "Oreo buttercream",
	},
	{
		flavor: "Gluten-Free",
		sponge: "Almond",
		filling: "Fresh raspberries",
		icing: "Lemon raspberry buttercream",
	},
	{
		flavor: "Pumpkin Spice",
		sponge: "Pumpkin spice",
		filling: "Salted caramel",
		icing: "Cream cheese buttercream",
	},
]

export default cakesData
