import React from "react";

import styles from './Select.module.scss';

const Option = ({ option, handleChange, isOptionSelected}) => {
  return (
    <li
      className={`${styles.multiLineOption} ${isOptionSelected ? styles.selected : ""}`}
      onMouseDown={(e) => {
        e.preventDefault();
        e.stopPropagation();
        handleChange(option.value);
      }}
    >
      <span className={styles.optionTitle}>{option.value}</span>
      <span className={styles.optionDescription}>{option.description}</span>
    </li>
  );
};

export default Option;
