import React from "react";
import emptyPlateImg from "../../assets/PNG/cake_crumbs.png"
import styles from "./error404.module.scss";

const Error404 = () => {
  return (
    <div class={styles.container}>
      <div class={styles.wrapper}>
        <h1 class={styles.title}>Oops!</h1>
        <p class={styles.text}>Page not found.</p>
      </div>
      <img class={styles.image} src={emptyPlateImg} alt="An empty plate with a fork and cake crumbs."/>
    </div>
  )
};

export default Error404;
