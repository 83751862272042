import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

import styles from "./Accordion.module.scss";

const Accordion = ({ id, title, isOpen, handleChange, children }) => {

  // TODO show input errors count + warning icon on the far right of accordion bar

  return (
    <div id={id} className={`${styles.accordion} ${isOpen ? styles.open : ""}`}>
      <div
        className={styles.accordionBar}
        onClick={() => handleChange()}
      >
        <FontAwesomeIcon className={styles.chevron} icon={faChevronRight} />
        <span className={styles.accordionTitle}>{title}</span>
      </div>
      <div className={styles.expander}>{children}</div>
    </div>
  );
};

export default Accordion;
