import { useNavigate } from "react-router-dom"
import styles from "./CakeCard.module.scss"

const CakeCard = ({
	isHovered,
	isAnyCardHovered,
	onHover,
	onLeave,
	flavor,
	sponge,
	filling,
	icing,
}) => {
	const navigate = useNavigate()

	const handleOrderClick = () => {
		navigate(`/order?cake=${encodeURIComponent(flavor)}`)
	}
	return (
		<div
			className={`${styles.card} ${isHovered ? styles.hovered : isAnyCardHovered ? styles.notHovered : styles.default}`}
			onMouseEnter={onHover}
			onMouseLeave={onLeave}
		>
			<h2 className={styles.h2}>{flavor}</h2>
			<p className={styles.title}>SPONGE</p>
			<p className={styles.value}>{sponge}</p>
			<p className={styles.title}>FILLING</p>
			<p className={styles.value}>{filling}</p>
			<p className={styles.title}>ICING</p>
			<p className={styles.value}>{icing}</p>
			<button onClick={handleOrderClick} className={styles.button}>
				Order Me!
			</button>
		</div>
	)
}

export default CakeCard
