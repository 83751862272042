import React, { useState, useEffect } from "react"
import Photo from "./Photo"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
	faExpand
} from "@fortawesome/free-solid-svg-icons"
import ArrowButton from "../../ArrowButton"

import styles from "./PhotoSlider.module.scss"

const PhotoSlider = ({ images, showExpander }) => {
	const [currentIndex, setCurrentIndex] = useState(0)
	const [showArrow, setShowArrow] = useState(true)

	useEffect(() => {
		if (images.length > 1) {
			setShowArrow(true)
		} else {
			setShowArrow(false)
		}
	}, [images.length])

	const handlePrev = () => {
		const prevIndex = currentIndex === 0 ? images.length - 1 : currentIndex - 1
		setCurrentIndex(prevIndex)
	}
	const handleNext = () => {
		const nextIndex = (currentIndex + 1) % images.length
		setCurrentIndex(nextIndex)
	}
	const handleExpand = () => {
		// TODO add expand functionality
	}

	const photoReel = [
		...images.map((image, index) => {
			return (
				<Photo key={index} image={image} alt={`Cake photo #${index}`} isActive={index === currentIndex} />
			)
		}),
	]

	return (
		<div className={styles.container}>
			{showExpander && 
				<FontAwesomeIcon
					className={styles.expander}
					icon={faExpand}
					onClick={() => handleExpand()}
				/>
			}
			{showArrow && (
				<ArrowButton
					disabled={''}
					arrowLocation="left"
					buttonSize="1.8em"
					theme={"light"}
					inStyles={{ left: "2px", zIndex: 5, scale: ".75" }} // reduce button size with 'scale'
					handleOnClick={() => handlePrev()}
				/>
			)}
			{photoReel}
			{showArrow && (
				<ArrowButton
				disabled={''}
				arrowLocation="right"
				buttonSize="1.8em"
				theme={"light"}
				inStyles={{ right: "2px", zIndex: 5, scale: ".75" }} // reduce button size with 'scale'
				handleOnClick={() => handleNext()}
			/>
			)}
			<span className={styles.indicators}>
				{showArrow && 
					images.map((_, index) => {
						return (
							<button
								key={index}
								className={`${styles.indicator} ${index === currentIndex ? styles.active : styles.inactive}`}
								onClick={() => setCurrentIndex(index)}
							></button>
						)
					})
				}
			</span>
		</div>
	)
}

export default PhotoSlider
