import React, { useState, useEffect, useRef } from "react"
import { createPortal } from "react-dom"
import { getDateISO } from "./helpers/calendar"
import Datepicker from "./Datepicker/index"
import Calendar from "./Calendar/index"
import styles from "./DatePicker.module.scss"

const DatePicker = ({
	id,
	label,
	value,
	error,
	maxDate,
	minDate,
	autoFocus,
	isHidden,
	handleError,
	handleChange,
	handleFocus,
}) => {
	const [dateState, setDateState] = useState(value)
	const [dateError, setDateError] = useState(error)
	const [calendarOpen, setCalendarOpen] = useState(false)
	const [optionsPosition, setOptionsPosition] = useState({})
	const ref = useRef()

	useEffect(() => {
		setDateError(error)
	}, [error])

	const checkValue = (newValue) => {
		if (!newValue) {
			setDateError("Required")
			handleError("Required")
		} else {
			setDateError("")
			handleError("")
		}
	}
	const handleDateChange = (date) => {
		const newDate = date ? getDateISO(date) : null
		checkValue(newDate)
		setDateState(newDate)
		handleChange(newDate)
		closeCalendar()
	}

	const openOptions = () => {
		if (calendarOpen) return
		requestAnimationFrame(() => {
			const rect = ref.current.getBoundingClientRect()
			const scrollOffset = window.scrollY || document.documentElement.scrollTop // required to get the correct position accounting for scroll
			setOptionsPosition({
				top: `calc(${rect.bottom + scrollOffset}px + .25rem)`,
				left: rect.left,
				width: rect.width,
			})
		})
		setCalendarOpen(true)
	}

	const closeCalendar = () => {
		setCalendarOpen(false)
		focusInput()
	}

	const focusInput = () => {
		ref.current.children[0].focus()
	}

	const formatDate = (date) => {
		const [year, month, day] = date.split("-")
		return `${month} / ${day} / ${year}`
	}

	const inputClasses = `${styles.inputBox} ${value ? styles.active : ""} ${dateError ? styles.invalid : ""} ${isHidden ? styles.hidden : ""}`

	return (
		<div
			id={id}
			ref={ref}
			className={inputClasses}
			onClick={(e) => {
				e.preventDefault()
				e.stopPropagation()
				openOptions()
			}}
			onFocus={(e) => {
				e.preventDefault()
				e.stopPropagation()
				handleFocus()
				openOptions()
			}}
			onBlur={(e) => {
				e.preventDefault()
				e.stopPropagation()
				checkValue(dateState)
			}}
			// FIXME close the calendar when the outside of the component is clicked -- right now only selecting a date will close the calendar
		>
			<Datepicker
				id={`${id}-input`}
				value={dateState ? formatDate(dateState) : ""}
				autoFocus={autoFocus}
				error={dateError}
			/>
			{calendarOpen &&
				createPortal(
					<Calendar
						id={`${id}-calendar`}
						date={dateState && new Date(formatDate(dateState))}
						onDateChanged={handleDateChange}
						calendarStyle={optionsPosition}
						minDate={minDate}
						maxDate={maxDate}
					/>,
					document.body
				)}
			<label
				id={`${id}-label`}
				className={styles.inputLabel}
				htmlFor={`${id}-input`}
			>
				{label}
			</label>
			{dateError && (
				<span id={`${id}-error`} className={styles.inputError}>
					{dateError}
				</span>
			)}
		</div>
	)
}

export default DatePicker
