import React, { useState, useEffect } from "react"
import styles from "./Input.module.scss"

const Input = ({
	id,
	label,
	type,
	isHidden,
	value,
	error,
	min,
	handleError,
	handleChange,
	...inputProps
}) => {
	const [inputError, setInputError] = useState(error)
	let inputDiv // define in switch statement below.

	useEffect(() => {
		setInputError(error)
	}, [error])

	// Function to remove whitespace, format phone numbers
	const normalizePhoneNumber = (value, previousValue) => {
		// return nothing if no value
		if (!value) return value

		// only allows 0-9 inputs
		const currentValue = value.replace(/[^\d]/g, "")
		const cvLength = currentValue.length

		if (!previousValue || value.length > previousValue.length) {
			// returns: "x", "xx", "xxx"
			if (cvLength < 4) return currentValue

			// returns: "(xxx)", "(xxx) x", "(xxx) xx", "(xxx) xxx",
			if (cvLength < 7)
				return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`

			// returns: "(xxx) xxx-", (xxx) xxx-x", "(xxx) xxx-xx", "(xxx) xxx-xxx", "(xxx) xxx-xxxx"
			return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`
		}
	}

	// confirm the value is free of errors, malicious code, and matches the input type e.g. email is a valid email.
	const checkValues = (event) => {
		const nameFormat = /^[a-zA-Z ]+$/
		const addressFormat = /^[^!@$%^&*()+=\-[\]{};:'"\\|<>/?`~]*$/
		const emailFormat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
		const phoneFormat =
			/(?:(?:(\s*\(?([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\)?\s*(?:[.-]\s*)?)([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})/
		// used to identify which fields are address fields and require different validation
		const addressFields = [
			"Address Line One",
			"Address Line Two",
			"City",
			"Postal Code",
		]

		const { value, type } = event.target
		let errorMessage = ""

		if (value === "" && label !== "Address Line Two") {
			errorMessage = "Required"
		}

		if (type === "text" && !addressFields.includes(label)) {
			if (value === "") {
				errorMessage = "Required"
			} else if (!value.match(nameFormat)) {
				errorMessage = "Only letters"
			} else if (value.length < 2) {
				errorMessage = "2 letters min"
			} else if (value.length > 45) {
				errorMessage = "45 letters max"
			}
		}

		if (type === "email") {
			if (value === "") {
				errorMessage = "Required"
			} else if (!value.match(emailFormat)) {
				errorMessage = "Invalid email format"
			}
		}

		if (type === "tel") {
			if (value === "") {
				errorMessage = "Required"
			} else if (!value.match(phoneFormat)) {
				errorMessage = "Invalid phone number"
			}
		}

		if (type === "text" && addressFields.includes(label)) {
			if (value === "" && label !== "Address Line Two") {
				errorMessage = "Required"
			} else if (!value.match(addressFormat) && value !== "") {
				errorMessage = "Only letters, numbers, and # , ."
			} else if (value.length < 2 && value !== "") {
				errorMessage = "Enter valid address"
			}
		}

		// Set error message for input
		setInputError(errorMessage)
		handleError(errorMessage)
	}

	// confirm the number is value -> used in CheckValues function
	const checkPhoneNumber = (newValue, value) => {
		return normalizePhoneNumber(newValue, value)
	}

	switch (type) {
		case "textarea":
			inputDiv = (
				<textarea
					id={`${id}-input`}
					name={id}
					className={`${styles.input} ${styles.textarea} ${value ? styles.active : ""}`}
					type="textarea"
					value={value}
					onBlur={(event) => {
						checkValues(event)
					}}
					onChange={(e) => {
						handleChange(e.target.value)
					}}
					rows="3"
					wrap="hard"
					{...inputProps}
				/>
			)
			break
		case "tel":
			inputDiv = (
				<input
					id={`${id}-input`}
					name={id}
					className={`${styles.input} ${value ? styles.active : ""}`}
					type="tel"
					value={value}
					onBlur={(event) => {
						checkValues(event)
					}}
					onChange={(e, value) => {
						let formattedValue = checkPhoneNumber(e.target.value, value)
						handleChange(formattedValue)
					}}
					min={min}
					{...inputProps}
				/>
			)
			break
		default:
			inputDiv = (
				<input
					id={`${id}-input`}
					name={id}
					className={`${styles.input} ${value ? styles.active : ""}`}
					type={type}
					value={value}
					onBlur={(event) => {
						checkValues(event)
					}}
					onChange={(e) => {
						handleChange(e.target.value)
					}}
					min={min}
					{...inputProps}
				/>
			)
			break
	}

	const inputClasses = ` ${styles.inputBox} ${inputError ? styles.invalid : ""} ${isHidden === false ? styles.hidden : ""} ${label === "Message" ? styles.height60px : ""}`

	return (
		<div id={id} className={inputClasses}>
			{inputDiv}
			<label
				id={`${id}-label`}
				className={styles.inputLabel}
				htmlFor={`${id}-input`}
			>
				{label}
			</label>
			{inputError && (
				<span id={`${id}-error`} className={styles.inputError}>
					{inputError}
				</span>
			)}
		</div>
	)
}

export default Input
