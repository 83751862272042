import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
	faInstagram,
	faFacebook,
	faGithub,
} from "@fortawesome/free-brands-svg-icons"

import Navbar from "./navbar/Navbar"
import Home from "./home/Home"
import About from "./about/About"
import OrderForm from "./order/OrderForm"
import Cakes from "./cakes/Cakes"
import Pricing from "./pricing/Pricing"
import Developer from "./developer/Developer"
import logo from "../assets/SVG/pc_cake_dark.svg"
import Error404 from "./error/error404"

import styles from "./App.module.scss"
const App = () => {
	return (
		<Router>
			<div className={styles.App}>
				<header className={styles.header}>
					<Link className={styles.titleWrapper} to="/">
						<div className={styles.titleContainer}>
							<h2 className={styles.title}>
								<span className={styles.embolden}>Polish</span>ed
							</h2>
							<img
								src={logo}
								alt="small-slice-of-cake"
								className={styles.logo}
							/>
							<h2 className={styles.title}>Cakes</h2>
						</div>
					</Link>
					<Navbar id={styles.navbar} />
				</header>
				<div id={styles.content}>
					<Routes>
						<Route exact path="/" element={<Home />}></Route>
						<Route exact path="/order" element={<OrderForm />}></Route>
						<Route exact path="/cakes" element={<Cakes />}></Route>
						<Route exact path="/pricing" element={<Pricing />}></Route>
						<Route exact path="/about" element={<About />}></Route>
						<Route exact path="developer" element={<Developer />}></Route>
						<Route exact path="*" element={<Error404 />}></Route>
					</Routes>
				</div>
				<footer className={styles.footer}>
					<div className={styles.social}>
						<a
							href="https://instagram.com/polishedcakes"
							target="_blank"
							rel="noreferrer"
						>
							<FontAwesomeIcon icon={faInstagram} size="2x" />
						</a>
						<a href="https://facebook.com" target="_blank" rel="noreferrer">
							<FontAwesomeIcon icon={faFacebook} size="2x" />
						</a>
						<a
							href="https://github.com/Flynno-io/polished-cakes"
							target="_blank"
							rel="noreferrer"
						>
							<FontAwesomeIcon icon={faGithub} size="2x" />
						</a>
					</div>
				</footer>
			</div>
		</Router>
	)
}

export default App

// TODO (1) update Select keyboard functionality -- options scroll as the highlighted indicator goes up/down
// TODO (3) Add 'blocked out' dates for the calendar -- make blocked dates unselectable

// TODO (4) Update the lambda function to use the new form fields -- update the function and the HTML formatting
// TODO (5) Create a build of the React.js website -- clean up code before using the 'npm build' script
// TODO (6) Upload the new build folder to the AWS S3 bucket and create a new Route53 distribution
// TODO (7) Write jest tests for polishedcakes.com core features and functionality

// picturesque, pure, priceless, prized, pristine,
