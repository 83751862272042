// Home.js
import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import styles from "./home.module.scss"

import PhotoCard from "../general/PhotoCard"
import Carousel from "../general/Carousel"
import rawPhotoSets from "../../assets/photos/images"
import { assignUniquePositions, intToEnglish } from "../../helpers/index"

function Home() {
	// Get the screen size and dynamically render content
	// based on breakpoints defined for desktop, tablet, and mobile
	const mobileWidthBreakPoint = 500
	const desktopWidthBreakPoint = 1600 // switch from scattered photos to carousel
	const mobileHeightBreakPoint = 950 // switch to carousel if the hero image doesn't fit the screen
	const getScreenDeviceType = () => {
		const windowWidth = window.innerWidth
		const windowHeight = window.innerHeight
		if (windowWidth < mobileWidthBreakPoint) {
			return "mobile"
		} else if (windowWidth < desktopWidthBreakPoint) {
			return "tablet"
		} else if (windowHeight < mobileHeightBreakPoint) {
			return "tablet"
		} else {
			return "desktop"
		}
	}
	const [screenSize, setScreenSize] = useState(getScreenDeviceType())
	useEffect(() => {
		const handleResize = () => {
			setScreenSize(getScreenDeviceType())
		}
		window.addEventListener("resize", handleResize)
		return () => window.removeEventListener("resize", handleResize)
	}, [])

	const primaryButtonText = "Order"
	const secondaryButtonText = "Discover"

	// Desktop photos and Styles
	const alteredPhotoSets = assignUniquePositions(rawPhotoSets)
	const photosScattered = alteredPhotoSets.map((photoSet, index) => {
		const photoPosition = intToEnglish(photoSet.position)
		return (
			<div
				id={index}
				key={index}
				className={`${styles.photoContainerDesktop} ${styles[photoPosition]}`}
			>
				<PhotoCard photoSet={photoSet} />
			</div>
		)
	})
	// Tablet and Mobile photos and styles
	const photosAligned = alteredPhotoSets.map((photoSet, index) => {
		const photoPosition = intToEnglish(photoSet.position)
		return (
			<div
				id={index}
				key={index}
				className={`${styles.photoContainerAligned} ${styles[photoPosition]}`}
			>
				<PhotoCard photoSet={photoSet} />
			</div>
		)
	})

	return (
		<div className={styles.homeContainer}>
			{screenSize === "desktop" && (
				<div className={styles.heroContainerDesktop}>
					{photosScattered}
					<h2 className={styles.heroTextDesktop}>Welcome to</h2>
					<h1 className={styles.heroTitleDesktop}>
						<span className={styles.embolden}>Polish</span>ed Cakes
					</h1>
					<h2 className={styles.heroTextDesktop}>
						Personalized. Polish. Perfections.
					</h2>
					<div className={styles.ctaButtonContainer}>
						<Link to="/order">
							<button className={styles.ctaButtonDesktopPrimary}>
								{primaryButtonText}
							</button>
						</Link>
						<Link to="/cakes">
							<button className={styles.ctaButtonDesktopSecondary}>
								{secondaryButtonText}
							</button>
						</Link>
					</div>
				</div>
			)}

			{screenSize === "tablet" && ( // TODO Update logo to show stacked on tablet view
				<div className={styles.heroContainerTablet}>
					<h2 className={styles.heroTextTablet}>Welcome to</h2>
					<h1 className={styles.heroTitleTablet}>
						<span className={styles.embolden}>Polish</span>ed Cakes
					</h1>
					<h2 className={styles.heroTextTablet}>
						Personalized. Polish. Perfections.
					</h2>
					<Carousel photos={photosAligned} />
					<div className={styles.ctaButtonContainer}>
						<Link to="/order">
							<button className={styles.ctaButtonTabletPrimary}>
								{primaryButtonText}
							</button>
						</Link>
						<Link to="/cakes">
							<button className={styles.ctaButtonTabletSecondary}>
								{secondaryButtonText}
							</button>
						</Link>
					</div>
				</div>
			)}

			{screenSize === "mobile" && (
				<div className={styles.heroContainerMobile}>
					<h2 className={styles.heroTextMobile}>Welcome to</h2>
					<h1 className={styles.heroTitleMobile}>
						<span className={styles.embolden}>Polish</span>ed Cakes
					</h1>
					<h2 className={styles.heroTextMobile}>
						Personalized. Polish. Perfections.
					</h2>
					<Carousel photos={photosAligned} />
					<div className={styles.ctaButtonContainer}>
						<Link to="/order">
							<button className={styles.ctaButtonMobilePrimary}>
								{primaryButtonText}
							</button>
						</Link>
						<Link to="/cakes">
							<button className={styles.ctaButtonMobileSecondary}>
								{secondaryButtonText}
							</button>
						</Link>
					</div>
				</div>
			)}
		</div>
	)
}

export default Home
