export const section_1_milena = {
	title: "Milena",
	subtitle: "Hi, I'm",
	content:
		"Hi, my name is Milena. I was born and raised in Poland. I completed my bachelor’s degree in Banking, and my master’s degree in Finance and Business Accounting in Poznan. I met my husband during a study abroad in Germany in 2017, and almost two years later I moved across the globe to the United States, where we currently live. ",
}

export const section_2_poland = {
	title: "Bake",
	subtitle: "I love to",
	content:
		"Baking brings me great joy, and I’ve been loving it since I can remember. I decided to start baking custom cakes as a creative outlet after our first baby was born. I would bake cakes during her nap times, or after she would go to sleep at night. I love to create cakes that are not only beautiful, but also delicious! I find experimenting with different flavors and textures fun, and creating unique cakes as a result is an additional bonus!",
}

export const section_3_baking = {
	title: "Ingredients",
	subtitle: "It's all about the",
	content:
		"Top ingredients are what I care most about in my bakes. I believe that cakes should not only look as Polished as possible, but most of all, they should taste great and be of high quality. I make sponges and buttercream from scratch. I use real butter, organic eggs, and unbleached flour in my bakes, to name a few. If you’d like to see some of my creations, check out my Instagram profile below!",
}
