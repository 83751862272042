import React from "react"
import Table from "../general/Table"
import styles from "./Pricing.module.scss"

const Pricing = () => {
	return (
		<div className={styles.page}>
			<h1 className={styles.h1}>Pricing</h1>
			<p className={styles.subtext}>
				Cakes start at a set price based on their size and number of tiers.
			</p>
			<p className={styles.subtext}>
				The price <span>can</span> increase based on additional design and
				complexity.
			</p>
			<div className={styles.container}>
				<h2 className={styles.h2}>Classic Cakes</h2>
				<p className={styles.sectionText}>
					Birthday, Baby Shower, Anniversary, work party, etc.
				</p>
				<Table
					caption="1 tiered cake"
					tableData={[
						["Size", "Servings", "Price"],
						["6 inch", "12", "$65"],
						["8 inch", "20", "$80"],
						["10 inch", "28", "$110"],
						["12 inch", "40", "$135"],
					]}
				/>
				<Table
					caption="2 tiered cake"
					tableData={[
						["Size", "Servings", "Price"],
						["4 & 6 inch", "20", "$160"],
						["6 & 8 inch", "32", "$220"],
						["8 & 10 inch", "48", "$350"],
					]}
				/>
				<Table
					caption="3 tiered cake"
					tableData={[
						["Size", "Servings", "Price"],
						["4, 6, & 8 inch", "40", "$290"],
						["6, 8, & 10 inch", "60", "$400"],
						["8, 10, & 12 inch", "88", "$520"],
					]}
				/>
			</div>
			<div className={styles.container}>
				<h2 className={styles.h2}>Baby Smash Cake</h2>
				<p className={styles.sectionText}>
					Cakes for your little one's first birthday. Perfect for smashing
				</p>
				<Table
					caption="Single tiered cake"
					tableData={[
						["Size", "Price"],
						["4 inch", "$45"],
						["6 inch", "$60"],
					]}
				/>
			</div>
			<div className={styles.container}>
				<h2 className={styles.h2}>Number Cakes</h2>
				<p className={styles.sectionText}>
					Cakes in the shape of a number. Perfect for birthdays!
				</p>
				<Table
					caption="Single tiered cake"
					tableData={[
						["Size", "Servings", "Price"],
						["1 number", "15-20", "$70"],
						["2 numbers", "35-40", "$125"],
						["3 numbers", "50-60", "$200"],
					]}
					note={`Cakes are carved from 10" x 12" pan.`}
				/>
			</div>
		</div>
	)
}

export default Pricing
