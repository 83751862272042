import React, { useState } from 'react'

import styles from "./PhotoSlider.module.scss"

const Photo = ({ image, alt, isActive }) => {
  const [isLoaded, setIsLoaded] = useState(false)

  return (
    <div className={`${styles.photo} ${isActive ? styles.active : styles.inactive} ${styles.loading}`}>
      <img
        className={`${styles.image} ${isLoaded ? styles.loaded : ""}`}
        src={image}
        alt={alt}
        loading="lazy"
        onLoad={() => setIsLoaded(true)}
      />
      <span className={styles.insetShadow}></span>
    </div>
  )
}

export default Photo