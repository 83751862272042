import React from "react"
import PhotoSlider from "./PhotoSlider"
import styles from "./photoCard.module.scss"

const PhotoCard = ({ photoSet, inStyles }) => {
	return (
		<div style={inStyles} className={styles.container}>
			<PhotoSlider images={photoSet.images} showExpander={false} />
			<h1 className={styles.title}>{photoSet.title}</h1>
		</div>
	)
}

export default PhotoCard
