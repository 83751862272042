import React from "react"
import { NavLink } from "react-router-dom"

import styles from "./Navbar.module.scss"

const Navbar = () => {
	const setClassName = ({ isActive }) =>
		`${styles.link} ${isActive && styles.active}`
	return (
		<>
			{/* <p className={styles.currentPage}>Page</p> */}
			{/* <FontAwesomeIcon className={styles.menu} icon={faBars} size="lg"/> */}
			<nav className={styles.navbar}>
				<NavLink
					id={styles.home} // used to hide the home link in the mobile menu
					to="/"
					className={({ isActive }) =>
						`${setClassName({ isActive })} ${styles.home}`
					}
				>
					Home
				</NavLink>
				<NavLink
					to="/order"
					className={({ isActive }) => setClassName({ isActive })}
				>
					Order
				</NavLink>
				<NavLink
					to="/cakes"
					className={({ isActive }) => setClassName({ isActive })}
				>
					Cakes
				</NavLink>
				<NavLink
					to="/pricing"
					className={({ isActive }) => setClassName({ isActive })}
				>
					Pricing
				</NavLink>
				<NavLink
					to="/about"
					className={({ isActive }) => setClassName({ isActive })}
				>
					About
				</NavLink>
			</nav>
		</>
	)
}

export default Navbar
