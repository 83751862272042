import { useState } from "react"
import { useLocation } from "react-router-dom"
import { add } from "date-fns"
import Input from "./input/Input"
import Select from "./input/select/Select"
import SelectInfo from "./input/select/SelectInfo"
import ToggleInput from "./toggleInput/ToggleInput"
import DatePicker from "./DatePicker"
import TimePicker from "./TimePicker"
import Accordion from "./accordion/Accordion"
import {
	cakeTypes,
	cakeTiers,
	cakeFlavors,
	deliveryOptions,
} from "./orderOptions/formOptions"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons"
import styles from "./OrderForm.module.scss"
import { useNavigate } from "react-router-dom"

const OrderForm = () => {
	const location = useLocation()
	const navigate = useNavigate()
	const queryParams = new URLSearchParams(location.search)
	const cakeFlavor = queryParams.get("cake")

	// Dictionary for Field Names
	const fieldNames = {
		fname: "First Name",
		lname: "Last Name",
		email: "Email",
		phone: "Phone Number",
		cakeType: "Cake Type",
		cakeTiers: "Tiers",
		cakeFlavors: "Cake Flavor",
		deliverOrder: "Delivery Option",
		addressLineOne: "Address Line One",
		addressLineTwo: "Address Line Two",
		city: "City",
		orderDate: "Order Date",
		orderTime: "Order Time",
		message: "Message",
	}

	const [isSubmitting, setIsSubmitting] = useState(false)
	const [isSubmitted, setIsSubmitted] = useState(false)
	const [isFormError, setIsFormError] = useState(false)
	const [isServerError, setIsServerError] = useState(false)
	const [formData, setFormData] = useState({
		fname: "",
		lname: "",
		email: "",
		phone: "",
		cakeType: "",
		cakeTiers: "",
		cakeFlavors: cakeFlavor || "",
		deliverOrder: false,
		addressLineOne: "",
		addressLineTwo: "",
		city: "",
		orderDate: "",
		orderTime: "",
		message: "",
	})
	const [formErrors, setFormErrors] = useState({
		fname: "",
		lname: "",
		email: "",
		phone: "",
		cakeType: "",
		cakeTiers: "",
		cakeFlavors: "",
		deliverOrder: "",
		addressLineOne: "",
		addressLineTwo: "",
		city: "",
		orderDate: "",
		orderTime: "",
		message: "",
	})

	const [accordionState, setAccordionState] = useState({
		contactInfo: false,
		cakeInfo: false,
		deliveryInfo: false,
	})

	const openFocusedAccordion = (section, input = true) => {
		setAccordionState((prev) => {
			const tempObj = { ...prev }
			for (const key in tempObj) {
				if (key === section) {
					tempObj[key] = input ? input : !prev[section]
				} else {
					tempObj[key] = false
				}
			}
			return tempObj
		})
	}

	const updateFormData = (newFormData) => {
		setFormData((prevData) => ({
			...prevData,
			...newFormData,
		}))
	}

	const checkSubmittedFormValues = () => {
		const addressFields = ["addressLineOne", "addressLineTwo", "city"]

		let canSubmit = true
		// check if the form is valid
		for (const key in formData) {
			// Skip checking address fields if the order is not being delivered
			if (addressFields.includes(key) && !formData.deliverOrder) {
				continue
			}
			if (formData[key] === "") {
				setFormErrors((prev) => ({ ...prev, [key]: "Required" }))
				canSubmit = false
			} else if (formErrors[key] !== "") {
				console.log(`formErrors[${key}]`, formErrors[key])
				canSubmit = false
			}
		}
		return canSubmit
	}

	const handleSave = (event) => {
		// prevent the default http/ request on the submitted form
		event.preventDefault()
		event.stopPropagation()

		setIsSubmitting(true)

		const canSubmit = checkSubmittedFormValues()
		if (!canSubmit) {
			setIsSubmitting(false)
			setIsFormError(true)
			return
		} else {
			setIsFormError(false)
		}

		// call POST /submit-order to send order details to Milena and Submitter
		fetch("https://api.polishedcakes.com/submit-order", {
			method: "POST",
			body: JSON.stringify(formData),
			headers: {
				"Content-Type": "application/json",
				Origin: window.location.hostname,
			},
		})
			.then((res) => res.json())
			.then((data) => {
				if (data.orderStatus === "success") {
					setIsSubmitting(false)
					setIsSubmitted(true)
				}
			})
			.catch((error) => {
				console.error("Error submitting order:", error)
				setIsSubmitting(false)
				setIsServerError(true)
			})
			.finally(() => {
				setIsSubmitting(false)
			})
	}

	return (
		<>
			<form
				noValidate
				id="orderForm"
				className={`${styles.form} ${isSubmitted && styles.hide}`}
				onSubmit={handleSave}
			>
				<h1 className={styles.formTitle}>Bake Request</h1>
				<p className={styles.formInstructions}>
					Submit a bake request and Milena will contact you within the next{" "}
					<b>2 business days</b> to discuss the details of your personalized
					cake.
				</p>

				<Accordion
					id="contactInfo"
					title="CONTACT INFO"
					isOpen={accordionState.contactInfo}
					handleChange={() => openFocusedAccordion("contactInfo", false)}
				>
					<Input
						id={styles.fname} // Required for the OrderForm CSS to select and position the input
						label="First Name"
						type="text"
						value={formData.fname}
						error={formErrors.fname}
						handleError={(error) => {
							setFormErrors((prev) => ({ ...prev, fname: error }))
						}}
						handleChange={(newValue) => {
							updateFormData({ fname: newValue })
						}}
						onFocus={() => {
							openFocusedAccordion("contactInfo")
						}}
						autoFocus
					/>
					<Input
						id={styles.lname} // Required for the OrderForm CSS to select and position the input
						label="Last Name"
						type="text"
						value={formData.lname}
						error={formErrors.lname}
						handleError={(error) => {
							setFormErrors((prev) => ({ ...prev, lname: error }))
						}}
						handleChange={(newValue) => {
							updateFormData({ lname: newValue })
						}}
						onFocus={() => {
							openFocusedAccordion("contactInfo")
						}}
					/>
					<Input
						id={styles.email} // Required for the OrderForm CSS to select and position the input
						label="Email"
						type="email"
						value={formData.email}
						error={formErrors.email}
						handleError={(error) => {
							setFormErrors((prev) => ({ ...prev, email: error }))
						}}
						handleChange={(newValue) => {
							updateFormData({ email: newValue })
						}}
						onFocus={() => {
							openFocusedAccordion("contactInfo")
						}}
					/>
					<Input
						id={styles.phone} // Required for the OrderForm CSS to select and position the input
						label="Phone Number"
						type="tel"
						value={formData.phone}
						error={formErrors.phone}
						handleError={(error) => {
							setFormErrors((prev) => ({ ...prev, phone: error }))
						}}
						handleChange={(newValue) => {
							updateFormData({ phone: newValue })
						}}
						onFocus={() => {
							openFocusedAccordion("contactInfo")
						}}
					/>
				</Accordion>
				<Accordion
					id="cakeInfo"
					title="CAKE INFO"
					isOpen={accordionState.cakeInfo}
					handleChange={() => openFocusedAccordion("cakeInfo", false)}
				>
					<Select
						id={styles.cakeType} // Required for the OrderForm CSS to select and position the input
						label="Cake Type"
						type="select"
						options={cakeTypes}
						value={formData.cakeType}
						error={formErrors.cakeType}
						handleError={(error) => {
							setFormErrors((prev) => ({ ...prev, cakeType: error }))
						}}
						handleChange={(newValue) => {
							updateFormData({ cakeType: newValue })
						}}
						handleFocus={() => {
							openFocusedAccordion("cakeInfo")
						}}
						// TODO add error check
					/>
					<Select
						id={styles.cakeTiers} // Required for the OrderForm CSS to select and position the input
						label="Tiers"
						type="select"
						options={cakeTiers}
						value={formData.cakeTiers}
						error={formErrors.cakeTiers}
						handleError={(error) => {
							setFormErrors((prev) => ({ ...prev, cakeTiers: error }))
						}}
						handleChange={(newValue) => {
							updateFormData({ cakeTiers: newValue })
						}}
						handleFocus={() => {
							openFocusedAccordion("cakeInfo")
						}}
						// TODO add error check
					/>
					<SelectInfo
						id={styles.cakeFlavors} // Required for the OrderForm CSS to select and position the input
						type="selectWithInfo"
						label="Cake Flavor"
						options={cakeFlavors}
						value={formData.cakeFlavors}
						error={formErrors.cakeFlavors}
						handleError={(error) => {
							setFormErrors((prev) => ({ ...prev, cakeFlavors: error }))
						}}
						handleChange={(newValue) => {
							updateFormData({ cakeFlavors: newValue })
						}}
						handleFocus={() => {
							openFocusedAccordion("cakeInfo")
						}}
					/>
				</Accordion>
				<Accordion
					id="deliveryInfo"
					title="DELIVERY INFO"
					isOpen={accordionState.deliveryInfo}
					handleChange={() => openFocusedAccordion("deliveryInfo", false)}
				>
					<ToggleInput
						id={styles.deliveryOption} // Required for the OrderForm CSS to select and position the input
						options={deliveryOptions}
						value={formData.deliverOrder}
						optionTrue="Delivery - $15"
						optionFalse="Pickup - Free"
						handleChange={(newValue) => {
							updateFormData({ deliverOrder: newValue })
						}}
						onFocus={() => {
							openFocusedAccordion("deliveryInfo")
						}}
					/>
					<Input
						id={styles.addressLineOne} // Required for the OrderForm CSS to select and position the input
						label="Address Line One"
						isHidden={formData.deliverOrder}
						type="text"
						value={formData.addressLineOne}
						error={formErrors.addressLineOne}
						handleError={(error) => {
							setFormErrors((prev) => ({ ...prev, addressLineOne: error }))
						}}
						handleChange={(newValue) => {
							updateFormData({ addressLineOne: newValue })
						}}
						onFocus={() => {
							openFocusedAccordion("deliveryInfo")
						}}
					/>
					<Input
						id={styles.addressLineTwo} // Required for the OrderForm CSS to select and position the input
						label="Address Line Two"
						isHidden={formData.deliverOrder}
						type="text"
						value={formData.addressLineTwo}
						error={formErrors.addressLineTwo}
						handleError={(error) => {
							setFormErrors((prev) => ({ ...prev, addressLineTwo: error }))
						}}
						handleChange={(newValue) => {
							updateFormData({ addressLineTwo: newValue })
						}}
						onFocus={() => {
							openFocusedAccordion("deliveryInfo")
						}}
					/>
					<Input
						id={styles.city} // Required for the OrderForm CSS to select and position the input
						label="City"
						isHidden={formData.deliverOrder}
						type="text"
						value={formData.city}
						error={formErrors.city}
						handleError={(error) => {
							setFormErrors((prev) => ({ ...prev, city: error }))
						}}
						handleChange={(newValue) => {
							updateFormData({ city: newValue })
						}}
						onFocus={() => {
							openFocusedAccordion("deliveryInfo")
						}}
					/>
					<DatePicker
						id={styles.orderDate} // Required for the OrderForm CSS to select and position the input
						label={formData.deliverOrder ? "Delivery Date" : "Pickup Date"}
						value={formData.orderDate}
						error={formErrors.orderDate}
						minDate={add(new Date(), { days: 4 })} // TODO: finish implementing the minDate
						maxDate={add(new Date(), { years: 1 })} // TODO: finish implementing the maxDate
						handleError={(error) => {
							setFormErrors((prev) => ({ ...prev, orderDate: error }))
						}}
						handleChange={(newValue) => {
							updateFormData({ orderDate: newValue })
						}}
						handleFocus={() => {
							// handleFocus is passed into the DatePicker component 'onFocus' handler that controls the
							// calendar open/close state
							openFocusedAccordion("deliveryInfo")
						}}
					/>
					<TimePicker
						id={styles.orderTime} // Required for the OrderForm CSS to select and position the input
						label={formData.deliverOrder ? "Delivery Time" : "Pickup Time"}
						value={formData.orderTime}
						error={formErrors.orderTime}
						handleError={(error) => {
							setFormErrors((prev) => ({ ...prev, orderTime: error }))
						}}
						handleChange={(newValue) => {
							updateFormData({ orderTime: newValue })
						}}
						handleFocus={() => {
							// handleFocus is passed into the TimePicker component 'onFocus' handler that controls the
							// selector's open/close state
							openFocusedAccordion("deliveryInfo")
						}}
					/>
					<Input
						id={styles.message} // Required for the OrderForm CSS to select and position the input
						label="Message"
						type="textarea"
						value={formData.message}
						error={formErrors.message}
						handleError={(error) => {
							setFormErrors((prev) => ({ ...prev, message: error }))
						}}
						handleChange={(newValue) => {
							updateFormData({ message: newValue })
						}}
						onFocus={() => {
							openFocusedAccordion("deliveryInfo")
						}}
					/>
				</Accordion>

				<button
					className={`${styles.submit} ${isSubmitting && styles.submitting}`}
					type="submit"
					disabled={isSubmitting}
				>
					{isSubmitting ? "Submitting..." : "Bake me a cake!"}
				</button>
				{/* Show server error if there is one */}
				{isServerError && (
					<div className={styles.formErrors}>
						<p className={styles.error}>
							There was an error submitting your order. Please try again later.
						</p>
					</div>
				)}
				{/* Show form errors if there are any */}
				{isFormError && (
					<div className={styles.formErrors}>
						<p className={styles.error}>
							There are some errors in the form. Please fix them and resubmit.
						</p>
						<ul className={styles.errorList}>
							{Object.entries(formErrors).map(([key, error]) => {
								if (error) {
									return (
										<li key={key} className={styles.errorItem}>
											<span>{fieldNames[key]}</span>: <i>{error}</i>
										</li>
									)
								}
								return null
							})}
						</ul>
					</div>
				)}
			</form>

			<div className={`${styles.orderSent} ${isSubmitted && styles.open}`}>
				<FontAwesomeIcon
					icon={faPaperPlane}
					size="3x"
					className={styles.sentIcon}
				/>
				<p className={styles.orderSentTitle}>Request Sent</p>
				<p className={styles.orderSentText}>Thank you for your order!</p>
				<p className={styles.orderSentText}>
					Milena will contact you within <b>24 hours</b> to discuss the details
					of your personalized cake.
				</p>
				<button onClick={() => navigate(0)} className={styles.orderSentButton}>
					Order again?
				</button>
			</div>
		</>
	)
}

export default OrderForm
