import React, { useEffect, useState, useRef } from "react"
import { getHour, getMinute, padStart } from "./utils"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons"
import styles from "./Selector.module.scss"

const Selector = ({ id, time, onTimeChange, selectorStyle, closeDial }) => {
	const selectorRef = useRef(null)
	// eslint-disable-next-line
	const [timeState, setTimeState] = useState(() => {
		if (time) {
			return time
		} else {
			const now = new Date()
			const hours = now.getHours() % 12 || 12
			let minutes = now.getMinutes()
			minutes = Math.round(minutes / 5) * 5
			return `${hours}:${minutes < 10 ? `0${minutes}` : minutes} ${hours < 12 ? "AM" : "PM"}`
		}
	})

	// Create the options for each current time and time type
	const createItemsArray = (time, type) => {
		let itemsArray = []
		if (type === "hour") {
			itemsArray = [
				time - 2 < 1 ? 12 + time - 2 : time - 2,
				time - 1 < 1 ? 12 + time - 1 : time - 1,
				time,
				time + 1 > 12 ? time + 1 - 12 : time + 1,
				time + 2 > 12 ? time + 2 - 12 : time + 2,
			]
		} else if (type === "minute") {
			// set to show every 5 minute increment
			itemsArray = [
				time - 10 < 0 ? 60 + time - 10 : time - 10,
				time - 5 < 0 ? 60 + time - 5 : time - 5,
				time,
				time + 5 > 59 ? time + 5 - 60 : time + 5,
				time + 10 > 59 ? time + 10 - 60 : time + 10,
			]
		} else {
			if (time === "AM") {
				itemsArray = ["-", "AM", "PM"]
			} else {
				itemsArray = ["AM", "PM", "-"]
			}
		}
		return itemsArray
	}

	// create hour, minute, and meridian state objects
	const [hour, setHour] = useState({
		type: "hour",
		time: getHour(timeState),
		options: createItemsArray(getHour(timeState), "hour"),
	})
	const [minute, setMinute] = useState({
		type: "minute",
		time: getMinute(timeState),
		options: createItemsArray(getMinute(timeState), "minute"),
	})
	const [meridian, setMeridian] = useState({
		type: "meridian",
		time: timeState.split(" ")[1],
		options: createItemsArray(time.split(" ")[1], "meridian"),
	})

	// Update the time when the user clicks on a new time (hour, minute, or meridian)
	useEffect(() => {
		onTimeChange(
			`${padStart(hour.time)}:${padStart(minute.time)} ${meridian.time}`
		)
		// eslint-disable-next-line
	}, [hour, minute, meridian])

	// Update the time when the user clicks on a new time (hour, minute, or meridian)
	const handleClick = (e, item) => {
		e.preventDefault()
		e.stopPropagation()
		const id = e.target.parentElement.id
		if (id === "hour") {
			setHour({ ...hour, time: item, options: createItemsArray(item, "hour") })
		} else if (id === "minute") {
			setMinute({
				...minute,
				time: item,
				options: createItemsArray(item, "minute"),
			})
		} else {
			setMeridian({
				...meridian,
				time: item === "-" ? meridian.time : item,
				options: createItemsArray(item, "meridian"),
			})
		}
		onTimeChange(
			`${padStart(hour.time)}:${padStart(minute.time)} ${meridian.time}`
		)
	}

	// create list items for each time option (hour, minute, or meridian)
	const renderItems = (state) => {
		const { type, time, options } = state
		return options.map((item, index) => (
			<li
				key={index}
				className={`${type === "hour" && time === item ? styles.active : ""} ${type === "minute" && time === item ? styles.active : ""} ${type === "meridian" && time === item ? styles.active : ""}`}
				onClick={(e) => handleClick(e, item)}
			>
				{type === "meridian" ? item : padStart(item)}
			</li>
		))
	}

	const handlePrev = (type, time) => {
		if (type === "hour") {
			const newHour = hour.time - 1 < 1 ? 12 : hour.time - 1
			setHour({
				...hour,
				time: newHour,
				options: createItemsArray(newHour, "hour"),
			})
		} else if (type === "minute") {
			const newMinute = minute.time - 5 < 0 ? 55 : minute.time - 5
			setMinute({
				...minute,
				time: newMinute,
				options: createItemsArray(newMinute, "minute"),
			})
		} else {
			const newMeridian = meridian.time === "AM" ? "PM" : "AM"
			setMeridian({
				...meridian,
				time: newMeridian,
				options: createItemsArray(newMeridian, "meridian"),
			})
		}
	}

	const handleNext = (type, time) => {
		if (type === "hour") {
			const newHour = hour.time + 1 > 12 ? 1 : hour.time + 1
			setHour({
				...hour,
				time: newHour,
				options: createItemsArray(newHour, "hour"),
			})
		} else if (type === "minute") {
			const newMinute = minute.time + 5 > 55 ? 0 : minute.time + 5
			setMinute({
				...minute,
				time: newMinute,
				options: createItemsArray(newMinute, "minute"),
			})
		} else {
			const newMeridian = meridian.time === "AM" ? "PM" : "AM"
			setMeridian({
				...meridian,
				time: newMeridian,
				options: createItemsArray(newMeridian, "meridian"),
			})
		}
		onTimeChange(
			`${padStart(hour.time)}:${padStart(minute.time)} ${meridian.time}`
		)
	}

	return (
		<div
			id={id}
			ref={selectorRef}
			style={selectorStyle}
			className={styles.container}
			tabIndex={-1} // FIXME: focus the parent component using tab from the selector (child) component
			onBlur={() => closeDial()} // FIXME: make onBlur focus the parent component && close the dial
		>
			{[hour, minute, meridian].map((state, index) => (
				<div key={index} className={styles.scroller}>
					<FontAwesomeIcon
						className={styles.ArrowUp}
						icon={faChevronLeft}
						onClick={() => handlePrev(state.type, state.time)}
					/>
					<ul className={styles.options} id={state.type}>
						{renderItems(state)}
					</ul>
					<FontAwesomeIcon
						className={styles.ArrowDown}
						icon={faChevronLeft}
						onClick={() => handleNext(state.type, state.time)}
					/>
				</div>
			))}
		</div>
	)
}

export default Selector
