import React, { useState, useEffect, useRef } from "react"
import { createPortal } from "react-dom"
import styles from "./TimePicker.module.scss"
import Selector from "./Selector"
import Input from "./Input"

const TimePicker = ({
	id,
	label,
	value,
	error,
	autoFocus,
	isHidden,
	handleError,
	handleChange,
	handleFocus,
	focusParent,
}) => {
	const [timeState, setTimeState] = useState(value)
	const [timeError, setTimeError] = useState(error)
	const [dialOpen, setDialOpen] = useState(false)
	const [selectorPosition, setSelectorPosition] = useState({})
	const inputRef = useRef()

	useEffect(() => {
		setTimeError(error)
	}, [error])

	const checkValue = (newValue) => {
		if (!newValue) {
			setTimeError("Required")
			handleError("Required")
		} else {
			setTimeError("")
			handleError("")
		}
	}
	const handleTimeChange = (time) => {
		checkValue(time)
		setTimeState(time)
		handleChange(time)
	}
	const openDial = () => {
		if (dialOpen) return
		requestAnimationFrame(() => {
			const rect = inputRef.current.getBoundingClientRect()
			const scrollOffset = window.scrollY || document.documentElement.scrollTop // required to get the correct position accounting for scroll
			setSelectorPosition({
				top: `calc(${rect.bottom + scrollOffset}px + 5px)`,
				left: rect.left,
				width: rect.width,
			})
		})
		setDialOpen(true)
	}
	const closeDial = () => {
		setDialOpen(false)
	}

	const inputClasses = `${styles.inputBox} ${timeState ? styles.active : ""} ${timeError ? styles.invalid : ""} ${isHidden ? styles.hidden : ""}`

	return (
		<div
			id={id}
			ref={inputRef}
			className={inputClasses}
			onClick={(e) => {
				e.preventDefault()
				e.stopPropagation()
				openDial()
			}}
			onFocus={(e) => {
				e.preventDefault()
				handleFocus()
				openDial()
			}}
			onBlur={(e) => {
				e.preventDefault()
				checkValue(timeState)
			}}
		>
			<Input
				id={`${id}-input`}
				value={timeState}
				autoFocus={autoFocus}
				closeDial={closeDial}
				error={timeError}
				saveBtnIsVisible={dialOpen}
			/>
			{dialOpen &&
				createPortal(
					<Selector
						id={`${id}-calendar`}
						time={timeState}
						onTimeChange={handleTimeChange}
						selectorStyle={selectorPosition}
						closeDial={closeDial}
					/>,
					document.body
				)}
			<label
				id={`${id}-label`}
				className={styles.inputLabel}
				htmlFor={`${id}-input`}
			>
				{label}
			</label>
			{timeError && (
				<span id={`${id}-error`} className={styles.inputError}>
					{timeError}
				</span>
			)}
		</div>
	)
}

export default TimePicker
