import React from "react"
import styles from "./Calendar.module.scss"

export const CalendarContainer = ({ calendarStyle, children }) => {
	return (
		<div className={styles.container} style={calendarStyle}>
			{children}
		</div>
	)
}

export const CalendarGrid = ({ children }) => {
	return <div className={styles.CalendarGrid}>{children}</div>
}

// constant styles for calendar component styling
const borderOn = "2px solid #ddd"
const fontWeightIn = "500"
const fontWeightOut = "300"
const colorOn = "#000"
const colorOff = "#ddd"

// For Styling -> prevent the corners of the bottom left and right date components of the calendar to stick outside the grid
const determineBorderRadius = (index) => {
	if (index === 35) {
		return "0 0 0 3px"
	}
	if (index === 41) {
		return "0 0 3px 0"
	}
	return "0 0 0 0"
}

export const HighlightedCalendarDate = ({
	index,
	value,
	inMonth,
	onClick,
	title,
}) => {
	const style = {
		borderBottom: `${(index + 1) / 7 <= 5 ? borderOn : "none"}`,
		borderRight: `${(index % 7) + 1 === 7 ? "none" : borderOn}`,
		fontWeight: `${inMonth ? fontWeightIn : fontWeightOut}`,
		color: `${inMonth ? colorOn : colorOff}`,
		gridRow: `${Math.floor(index / 7) + 2} / span 1`,
		borderRadius: determineBorderRadius(index),
		"--highlighted-border-radius": determineBorderRadius(index), // uses custom variable to set border-radius on .HighlightedCalendarDate::before
	}

	return (
		<div
			key={index}
			style={style}
			className={styles.HighlightedCalendarDate}
			onClick={onClick}
			title={title}
		>
			{value}
		</div>
	)
}

export const TodayCalendarDate = ({
	index,
	value,
	inMonth,
	onClick,
	title,
}) => {
	const style = {
		borderBottom: `${(index + 1) / 7 <= 5 ? borderOn : "none"}`,
		borderRight: `${(index % 7) + 1 === 7 ? "none" : borderOn}`,
		fontWeight: `${inMonth ? fontWeightIn : fontWeightOut}`,
		color: `${inMonth ? colorOn : colorOff}`,
		gridRow: `${Math.floor(index / 7) + 2} / span 1`,
		borderRadius: determineBorderRadius(index),
		"--today-border-radius": determineBorderRadius(index), // uses custom variable to set border-radius on .TodayCalendarDate::before
	}

	return (
		<div
			key={index}
			className={styles.TodayCalendarDate}
			style={style}
			onClick={onClick}
			title={title}
		>
			{value}
		</div>
	)
}

export const CalendarDate = ({ index, value, inMonth, onClick, title }) => {
	const style = {
		borderBottom: `${(index + 1) / 7 <= 5 ? borderOn : "none"}`,
		borderRight: `${(index % 7) + 1 === 7 ? "none" : borderOn}`,
		fontWeight: `${inMonth ? fontWeightIn : fontWeightOut}`,
		color: `${inMonth ? colorOn : colorOff}`,
		gridRow: `${Math.floor(index / 7) + 2} / span 1`,
		borderRadius: determineBorderRadius(index),
	}

	return (
		<div
			key={index}
			className={styles.CalendarDate}
			style={style}
			onClick={onClick}
			title={title}
		>
			{value}
		</div>
	)
}
