import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
	CalendarContainer,
	CalendarGrid,
	HighlightedCalendarDate,
	TodayCalendarDate,
	CalendarDate,
} from "./components"
import calendar, {
	isDate,
	isSameDay,
	isSameMonth,
	getDateISO,
	getNextMonth,
	getPreviousMonth,
	WEEK_DAYS,
	CALENDAR_MONTHS,
} from "../helpers/calendar"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
	faChevronLeft,
	faChevronRight,
} from "@fortawesome/free-solid-svg-icons"
import styles from "./Calendar.module.scss"

export default function Calendar({ date, calendarStyle, onDateChanged }) {
	const [dateState, setDateState] = useState({
		current: new Date(),
		month: new Date().getMonth() + 1,
		year: new Date().getFullYear(),
	})
	// eslint-disable-next-line
	const [today, setToday] = useState(new Date())
	useEffect(() => {
		addDateToState(date)
		// eslint-disable-next-line
	}, [])

	// TODO add support for keyboard navigation -- up, down, left, right, select (space), enter(close)

	const addDateToState = (date) => {
		const isDateObject = isDate(date)
		const _date = isDateObject ? date : new Date()
		setDateState({
			current: isDateObject ? date : null,
			month: +_date.getMonth() + 1,
			year: _date.getFullYear(),
		})
	}
	const getCalendarDates = () => {
		const { current, month, year } = dateState
		const calendarMonth = month || +current?.getMonth() + 1
		const calendarYear = year || current?.getFullYear()
		return calendar(calendarMonth, calendarYear)
	}
	const renderMonthAndYear = () => {
		const { month, year } = dateState
		// const formatter = new Intl.DateTimeFormat('zh-CN', {
		//   day: 'numeric',
		//   month: 'short',
		//   year: 'numeric'
		// });
		// const formattedDate = formatter.format(dateState.current);
		// console.log('formatted date', formattedDate)

		// Resolve the month name from the CALENDAR_MONTHS object map
		const monthName =
			Object.keys(CALENDAR_MONTHS)[Math.max(0, Math.min(month - 1, 11))]
		return (
			<div className={styles.CalendarHeader}>
				<button
					className={styles.ArrowLeft}
					onClick={handlePrevious}
					title="Previous Month"
				>
					<FontAwesomeIcon className={styles.selector} icon={faChevronLeft} />
				</button>
				<div className={styles.CalendarMonth}>
					{monthName} {year}
					{/* {formattedDate} */}
				</div>
				<button
					className={styles.ArrowRight}
					onClick={handleNext}
					title="Next Month"
				>
					<FontAwesomeIcon className={styles.selector} icon={faChevronRight} />
				</button>
			</div>
		)
	}
	// Render the label for day of the week
	// This method is used as a map callback as seen in render()
	const renderDayLabel = (day, index) => {
		// Resolve the day of the week label from the WEEK_DAYS object map
		const dayLabel = WEEK_DAYS[day].toUpperCase()

		// dynamically render border and grid column based on data and position
		const style = {
			borderRight: (index % 7) + 1 === 7 ? "none" : "2px solid #f0b1b6",
			gridColumn: `${(index % 7) + 1} / span 1`,
		}
		return (
			<div className={styles.CalendarDay} key={dayLabel} style={style}>
				{dayLabel}
			</div>
		)
	}
	// Render a calendar date as returned from the calendar builder function
	const renderCalendarDate = (date, index) => {
		const { current, month, year } = dateState

		const _date = new Date(date.join("-"))
		// Check if calendar date is same day as today
		const isToday = isSameDay(_date, today)
		// Check if calendar date is same day as currently selected date
		const isCurrent = current && isSameDay(_date, current)
		// Check if calendar date is in the same month as the state month and year
		const inMonth = isSameMonth(_date, new Date(year, month - 1, 1)) // updated to fix issue on iPhone web browsers (webkit)
		// The click handler
		const onClick = gotoDate(_date)
		const value = _date.getDate()
		const props = {
			index,
			value,
			inMonth,
			onClick,
			title: _date.toDateString(),
		}
		// Conditionally render a styled date component
		if (isCurrent) {
			return <HighlightedCalendarDate key={getDateISO(_date)} {...props} />
		} else if (isToday) {
			return <TodayCalendarDate key={getDateISO(_date)} {...props} />
		} else {
			return <CalendarDate key={getDateISO(_date)} {...props} />
		}
	}
	const gotoDate = (date) => (evt) => {
		evt && evt.preventDefault()
		evt && evt.stopPropagation()
		addDateToState(date)
		onDateChanged(date)
		// const { current } = dateState;
		// if (!(current && isSameDay(date, current))) {
		//   addDateToState(date);
		//   onDateChanged(date);
		// }
	}
	const gotoPreviousMonth = () => {
		const { month, year } = dateState
		const previousMonth = getPreviousMonth(month, year)
		setDateState({
			month: previousMonth.month,
			year: previousMonth.year,
			current: dateState.current,
		})
	}
	const gotoNextMonth = () => {
		const { month, year } = dateState
		const nextMonth = getNextMonth(month, year)
		setDateState({
			month: nextMonth.month,
			year: nextMonth.year,
			current: dateState.current,
		})
	}
	const handlePrevious = (evt) => {
		evt.preventDefault()
		evt.stopPropagation()

		gotoPreviousMonth()
	}
	const handleNext = (evt) => {
		evt.preventDefault()
		evt.stopPropagation()
		gotoNextMonth()
	}

	return (
		<CalendarContainer
			calendarStyle={calendarStyle}
			className={styles.container}
		>
			{renderMonthAndYear()}
			<CalendarGrid>
				{Object.keys(WEEK_DAYS).map(renderDayLabel)}
				{getCalendarDates().map(renderCalendarDate)}
			</CalendarGrid>
			{/* {renderFooter()} */}
		</CalendarContainer>
	)
}

Calendar.propTypes = {
	date: PropTypes.instanceOf(Date),
	// date: PropTypes.string,
	onDateChanged: PropTypes.func,
}

// TODO add blocked out dates: https://blog.logrocket.com/react-custom-datepicker/#:~:text=time%20picker%20option%3A-,Blocking%20certain%20dates,-To%20block%20certain
