import React from "react"

import styles from "./ToggleInput.module.scss"

const ToggleInput = ({
	id,
	error,
	optionTrue,
	optionFalse,
	handleChange,
	isHidden,
	...inputProps
}) => {
	const inputClasses = ` ${styles.inputBox} ${error ? styles.invalid : ""} ${isHidden ? styles.hidden : ""}`

	return (
		<div id={id} className={inputClasses}>
			<input
				id={`${id}-input`}
				name={id}
				className={styles.input}
				type="checkbox"
				data-true={optionTrue}
				data-false={optionFalse}
				onChange={(event) => {
					const newValue = event.target.checked
					handleChange(newValue)
				}}
				{...inputProps}
			/>
		</div>
	)
}

export default ToggleInput
