
export const assignUniquePositions = (data) => {
  const n = data.length;
  const numbers = Array.from({ length: n }, (_, i) => i); // concise array creation (0 to n-1)
  numbers.sort(() => Math.random() - 0.5); // shuffle using random sort

  for (let i = 0; i < n; i++) {
    data[i].position = numbers[i];
  }

  return data;
}

export const intToEnglish = (number) => {
  const numberNames = {
    0: "zero",
    1: "one",
    2: "two",
    3: "three",
    4: "four",
    5: "five",
    6: "six",
    7: "seven",
    8: "eight",
    9: "nine",
    10: "ten",
    11: "eleven",
    12: "twelve",
    13: "thirteen",
    14: "fourteen",
    15: "fifteen",
    16: "sixteen",
    17: "seventeen",
    18: "eighteen",
    19: "nineteen",
  };
  if (number < 0 || number > 19) {
    throw new Error("Number must be between 0 and 19");
  }
  return numberNames[number];
}