import React from "react"
import PhotoInfoCard from "../general/PhotoInfoCard"
import {
	photo_milena_cake,
	photo_family_poland,
	photo_family_birthday,
} from "../../assets/photos/images"
import {
	section_1_milena,
	section_2_poland,
	section_3_baking,
} from "../../assets/copy/about"

import styles from "./About.module.scss"

const About = () => {
	const selectRandomDegree = () => {
		const degrees = [-5, -4, -3 - 2, -1, 0, 1, 2, 3, 4, 5]
		const randomIndex = Math.floor(Math.random() * degrees.length)
		return `${degrees[randomIndex]}deg`
	}

	return (
		<div className={styles.container}>
			<h1 className={styles.title}>About Me</h1>
			<PhotoInfoCard
				type="left"
				photoObj={photo_milena_cake}
				title={section_1_milena.title}
				subtitle={section_1_milena.subtitle}
				content={section_1_milena.content}
				photoRotation={selectRandomDegree()}
				ctaText={"Order a cake now"}
				ctaLink={"/order"}
			/>
			<hr className={styles.separator} />
			<PhotoInfoCard
				type="right"
				photoObj={photo_family_poland}
				title={section_2_poland.title}
				subtitle={section_2_poland.subtitle}
				content={section_2_poland.content}
				photoRotation={selectRandomDegree()}
				ctaText={"See my Polish bakes"}
				ctaLink={"/cakes"}
			/>
			<hr className={styles.separator} />
			<PhotoInfoCard
				type="left"
				photoObj={photo_family_birthday}
				title={section_3_baking.title}
				subtitle={section_3_baking.subtitle}
				content={section_3_baking.content}
				photoRotation={selectRandomDegree()}
				ctaText={"Follow me on Instagram"}
				ctaLink={"https://www.instagram.com/polishedcakes/"}
				useATag={true}
			/>
		</div>
	)
}

export default About
