import React from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCalendar } from "@fortawesome/free-regular-svg-icons"
import styles from "./Datepicker.module.scss"
export default function Datepicker({ value, autoFocus, error }) {
	const handleChange = (e) => {
		e.preventDefault()
		e.stopPropagation()
	}

	return (
		<>
			<input
				className={`${styles.input} ${value ? styles.active : ""} ${error ? styles.invalid : ""}`}
				id="datepicker-input"
				name="datepicker"
				type="text"
				value={value}
				readOnly="readonly"
				onChange={handleChange}
				autoFocus={autoFocus}
			/>
			<FontAwesomeIcon icon={faCalendar} className={styles.icon} />
		</>
	)
}

Datepicker.propTypes = {
	value: PropTypes.string,
	autoFocus: PropTypes.bool,
	onDateChanged: PropTypes.func,
}
