import React from "react"
import styles from "./table.module.scss"

const Table = ({ caption, tableData, note }) => {
	return (
		<div className={styles.container}>
			<table className={styles.table}>
				<caption className={styles.caption}>{caption}</caption>
				<thead>
					<tr>
						{tableData[0].map((item, index) => (
							<th key={index} className={styles.th}>
								{item}
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					{tableData.slice(1).map((row, index) => (
						<tr key={index}>
							{row.map((item, index) => (
								<td key={index} className={styles.td}>
									{item}
								</td>
							))}
						</tr>
					))}
				</tbody>
			</table>
			{note && <p className={styles.note}>{note}</p>}
		</div>
	)
}

export default Table
